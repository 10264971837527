// src/app/UploadFileRow.jsx

import React from 'react';

//import { makeStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';

// Material-UI Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Clear';

/*
const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  wrapper: {
    position: 'relative',
  },
  fab: {
    margin: theme.spacing(1),
  },
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonFail: {
    margin: theme.spacing(1),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
}));
*/

function fmtProgress(progress) {
  return(progress.toFixed(2) + "%")
}

export default function UploadFileRow(props) {
  var upload;

  const addFile = (file, fileName) => {
    props.addFile(props.rowId, file, fileName);
  };
  const removeFile = () => {
    props.removeFile(props.rowId);
  }
  const getTooltip = (progress) => {
    const progMsg = "Uploading: " + fmtProgress(props.progress) + " complete"
    return((props.isLoading)
      ? progMsg
      : (props.isFailed) ? "Upload failed" : "");
  }

  const xsUp = useMediaQuery(theme => theme.breakpoints.up("xs"));
  const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme => theme.breakpoints.up("md"));
  const showXs = !smUp & xsUp;
  const showSm = !mdUp & smUp;
  const showMdUp = mdUp;

  return (
    <Box>
      {showXs ? <Box>
        <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
          <Grid item xs={8}>
            <TextField
              label={props.textFieldLabel}
              size="small"
              sx={{ width: "100%", ml: 1,  mr: 1 }}
              value={props.fileName}
              variant="filled"
              margin="dense"
              onClick={e => {
                if (!props.isComplete) {
                  upload.value = null;
                  upload.click();
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box sx={{position: 'relative'}}>
              <Fab
                color="primary"
                size="small"
                sx={[
                  { margin: 1,
                    bgcolor: props.isComplete ?
                              green[500] :
                              (props.isFailed ? red[500] : "primary.main")},
                  props.isComplete && {
                    '&:hover': { bgcolor: green[700] }
                  },
                  props.isFailed && {
                    '&:hover': { bgcolor: red[700] }
                  }
                    ]}
                disableRipple={props.isComplete}
                onClick={e => {
                  if (!props.isComplete) {
                    upload.value = null;
                    upload.click();
                  }
                }}
                >
                {(props.isComplete)
                  ? <DoneIcon/>
                  : (props.isFailed) ? <CancelIcon/> : <AddIcon/>}
              </Fab>
              {props.isLoading && props.progress > 2
                &&  <CircularProgress
                      size={48}
                      variant="determinate"
                      sx={{ color: green[500],
                            position: 'absolute',
                            top: 4,
                            left: 4,
                            zIndex: 1}}
                      value={props.progress}
                    />}
              {props.isLoading && props.progress <= 2
                &&  <CircularProgress
                      size={48}
                      variant="indeterminate"
                      sx={{ color: green[500],
                            position: 'absolute',
                            top: 4,
                            left: 4,
                            zIndex: 1}}
                      value={props.progress}
                    />}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{position: 'relative'}}>
              <Fab
                color="primary"
                size="small"
                sx={{m: 1}}
                disabled={props.disableRemove || props.isLoading || props.isComplete}
                onClick={removeFile}
              >
                <RemoveIcon/>
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </Box> : null}
      {showSm ? <Box>
        <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={1}/>
          <Grid item xs={8}>
            <TextField
              label={props.textFieldLabel}
              size="small"
              sx={{ width: "100%", ml: 1,  mr: 1 }}
              value={props.fileName}
              variant="filled"
              margin="dense"
              onClick={e => {
                if (!props.isComplete) {
                  upload.value = null;
                  upload.click();
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Box sx={{position: 'relative'}}>
              <Fab
                color="primary"
                size="small"
                sx={[
                  { margin: 1,
                    bgcolor: props.isComplete ?
                              green[500] :
                              (props.isFailed ? red[500] : "primary.main")},
                  props.isComplete && {
                    '&:hover': { bgcolor: green[700] }
                  },
                  props.isFailed && {
                    '&:hover': { bgcolor: red[700] }
                  }
                    ]}
                disableRipple={props.isComplete}
                onClick={e => {
                  if (!props.isComplete) {
                    upload.value = null;
                    upload.click();
                  }
                }}
                >
                {(props.isComplete)
                  ? <DoneIcon/>
                  : (props.isFailed) ? <CancelIcon/> : <AddIcon/>}
              </Fab>
              {props.isLoading && props.progress > 2
                &&  <CircularProgress
                      size={48}
                      variant="determinate"
                      sx={{ color: green[500],
                            position: 'absolute',
                            top: 4,
                            left: 4,
                            zIndex: 1}}
                      value={props.progress}
                    />}
              {props.isLoading && props.progress <= 2
                &&  <CircularProgress
                      size={48}
                      variant="indeterminate"
                      sx={{ color: green[500],
                            position: 'absolute',
                            top: 4,
                            left: 4,
                            zIndex: 1}}
                      value={props.progress}
                    />}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{position: 'relative'}}>
              <Fab
                color="primary"
                size="small"
                sx={{m: 1}}
                disabled={props.disableRemove || props.isLoading || props.isComplete}
                onClick={removeFile}
              >
                <RemoveIcon/>
              </Fab>
            </Box>
          </Grid>
          <Grid item xs={1}/>
        </Grid>
      </Box> : null}
      {showMdUp ? <Box>
        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center">
          <Grid item lg={8}>
            <TextField
              label={props.textFieldLabel}
              size="small"
              sx={{ width: "100%", ml: 1,  mr: 1 }}
              value={props.fileName}
              variant="filled"
              margin="dense"
              onClick={e => {
                if (!props.isComplete) {
                  upload.value = null;
                  upload.click();
                }
              }}
            />
          </Grid>
          <Grid item lg={2}>
            <Tooltip title={getTooltip(props.progress)} placement="right">
              <Box sx={{position: 'relative'}}>
                <Fab
                  color="primary"
                  size="small"
                  sx={[
                    { margin: 1,
                      bgcolor: props.isComplete ?
                                green[500] :
                                (props.isFailed ? red[500] : "primary.main")},
                    props.isComplete && {
                      '&:hover': { bgcolor: green[700] }
                    },
                    props.isFailed && {
                      '&:hover': { bgcolor: red[700] }
                    }
                      ]}
                  disableRipple={props.isComplete}
                  onClick={e => {
                    if (!props.isComplete) {
                      upload.value = null;
                      upload.click();
                    }
                  }}
                  >
                  {(props.isComplete)
                    ? <DoneIcon/>
                    : (props.isFailed) ? <CancelIcon/> : <AddIcon/>}
                </Fab>
                {props.isLoading && props.progress > 2
                  &&  <CircularProgress
                        size={48}
                        variant="determinate"
                        sx={{ color: green[500],
                              position: 'absolute',
                              top: 4,
                              left: 4,
                              zIndex: 1}}
                        value={props.progress}
                      />}
                {props.isLoading && props.progress <= 2
                  &&  <CircularProgress
                        size={48}
                        variant="indeterminate"
                        sx={{ color: green[500],
                              position: 'absolute',
                              top: 4,
                              left: 4,
                              zIndex: 1}}
                        value={props.progress}
                      />}
              </Box>
            </Tooltip>
          </Grid>
          <Grid item lg={2}>
            <Box sx={{position: 'relative'}}>
              <Fab
                color="primary"
                size="small"
                sx={{m: 1}}
                disabled={props.disableRemove || props.isLoading || props.isComplete}
                onClick={removeFile}
              >
                <RemoveIcon/>
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </Box> : null}
      <input
        type="file"
        style={{ display: "none" }}
        ref={ref => (upload = ref)}
        onChange={e => {
          const upfile = upload.files[0];
          const upfileName = (upfile) ? upfile.name : undefined;
          addFile(upfile, upfileName);
        }}
      />
    </Box>
  );
}

UploadFileRow.defaultProps = {
  rowId: null,
  fileName: "",
  file: null,
  textFieldLabel: "File name",

  addFile: () => {},
  removeFile: () => {},
  disableRemove: true,

  isLoading: false,
  isComplete: false,
  isFailed: false,

  progress: 0
}
