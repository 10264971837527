// src/App.js

import React from 'react';
import { ThemeProvider } from '@mui/material';

import UploadPanel from './app/UploadPanel.jsx';

import theme from './styles/theme.jsx';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UploadPanel/>
      </ThemeProvider>
    </div>
  );
}

export default App;
