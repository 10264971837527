// src/styles/theme.jsx

import { createTheme } from '@mui/material';

export const logoImage = process.env.PUBLIC_URL + "/banner.png";
export const backgroundImage = process.env.PUBLIC_URL + "/background.jpg";

const theme = createTheme({
  palette: {
    secondary: {
      light: '#cfcfcf',
      main: '#9e9e9e',
      dark: '#707070',
    },
    primary: {
      light: '#4b5266',
      main: '#222a3c',
      dark: '#000017',
    },
    error: {
      main: '#b71c1c',
    },
    background: {
      main: '#02071d',
      default: '#02071d'
    }
  },
  backgroundImage: backgroundImage,
  logoImage: logoImage
});

export default theme;
