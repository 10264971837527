// config.js

const config = {
  cognito: {
    COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION,
  },
  s3: {
    S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    S3_REGION: process.env.REACT_APP_S3_REGION,
  },
  minRoomIdLength: Number(process.env.REACT_APP_MIN_ROOM_ID_LENGTH)
};

export default config;
