// src/app/common/Footer.jsx

import React from 'react';

import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const StyledFooter = styled('footer')(({theme}) => ({
  fontSize: '12px',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  color: theme.palette.primary.contrastText,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
}));

export default function Footer(props) {
  return (
    <Box sx={props.sx}>
      <StyledFooter>
        {props.children}
      </StyledFooter>
    </Box>
  );
};
