// src/app/UploadPanel.jsx

import React from 'react';

// AWS-Amplify
import Storage from './aws-storage.js';

// Material-UI
import { styled } from '@mui/system';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Custon components
import Footer from './Footer.jsx';
import UploadFileRow from './UploadFileRow.jsx';
import formatBytes from './formatBytes.js';
import config from '../config.js';
import { logoImage } from '../styles/theme.jsx';

/*
const styles = theme => ({
  '@global': {
    body: {
      backgroundImage: "url(" + theme.backgroundImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed"
    },
  },
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerLink: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  mdPaper: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },
  smPaper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },
  centeredGrid: {
    textAlign: "center"
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: theme.spacing(2),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  logoPanel: {
    height: "70px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "top left"
  },
  logoImage: theme.logoImage,
});
*/
const termsUrl = "https://www.accretivelabs.com/terms-of-use/";
const privacyUrl = "https://www.accretivelabs.com/privacy-policy/";

function TermsNotice(props) {
  return(
    <Typography variant="caption">
      {"Uploads are subject to the "}
      <Link href={termsUrl} color="inherit" underline="none">
        Terms of Use
      </Link>
      {" and "}
      <Link href={privacyUrl} color="inherit" underline="none">
        Privacy Policy
      </Link>
      {"."}
    </Typography>
  )
}

function prefixFileName(fileName) {
  // Prefix filename with timestamp and random value for collision avoidance
  const ts = (new Date()).toISOString()
  const rnd = Math.random().toString(36).substring(6)
  return(ts + "_" + rnd + "_" + fileName);
}

const BackgroundBox = styled(Box)(({theme}) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundImage: "url(" + theme.backgroundImage + ")",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed"
}));

const LogoCardMedia = styled(CardMedia)(({theme}) => ({
  height: "70px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top left"
}));

class UploadPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responses: {},
      lastRow: 0,

      rowConfigs: {
        0: {
          uploadName: "",
          uploadFile: null,
          isUploading: false,
          isComplete: false,
          uploadProgress: 0
        }
      },
      isUploading: false,
      errorMessage: "",
    };
  }

  updateProgress = (rowId, progress) => {
    const tmp = Object.assign({}, this.state.rowConfigs[rowId]);
    this.setState({
      rowConfigs: {...this.state.rowConfigs,
        [rowId]: {
          ...tmp,
          uploadProgress: progress
        }
      }
    })
  };
  markComplete = async (rowId) => {
    var newRowConfig = Object.assign({}, this.state.rowConfigs[rowId]);
    newRowConfig.isUploading = false;
    newRowConfig.isComplete = true;
    newRowConfig.isFailed = false;
    this.setState({rowConfigs: {...this.state.rowConfigs, [rowId]: newRowConfig}});
  };
  markFailed = async (rowId) => {
    var newRowConfig = Object.assign({}, this.state.rowConfigs[rowId]);
    newRowConfig.isUploading = false;
    newRowConfig.isComplete = false;
    newRowConfig.isFailed = true;
    this.setState({rowConfigs: {...this.state.rowConfigs, [rowId]: newRowConfig}});
  };
  handleFailError = (err) => {
    console.log(err)
    switch(err.code) {
      case("ExpiredToken"):
        this.setState({
          errorMessage: "Upload timed out: please try smaller file sizes or use a faster network connection."});
        break;
      case("CredentialsError"):
      case("NetworkingError"):
        this.setState({
          errorMessage: "Connection error: please try again."});
        break;
      case("RequestAbortedError"):
        this.setState({errorMessage: "Request aborted."});
        break;
      default:
        this.setState({
          errorMessage: "Upload failed: please try again."});
    }
    // this.setState({errorMessage: err.message});
  }
  uploadFile = () => {
    var res = [],
        newRowConfig = {};
    const roomId = this._getRoomId();
    this.setState({errorMessage: ""});

    if (!this._validateRoomId(roomId)) {
      console.error("Invalid upload room:", roomId);
      this.setState({errorMessage: "Invalid upload room: Please try pasting your upload link into the browser and loading the page again."});
      return;
    }

    this.setState({isUploading: true});

    for (let k of Object.keys(this.state.rowConfigs)) {
      if (this.state.rowConfigs[k].uploadFile &&
          !this.state.rowConfigs[k].isComplete) {
        newRowConfig[k] = Object.assign({}, this.state.rowConfigs[k]);
        newRowConfig[k].isUploading = true;
        newRowConfig[k].uploadProgress = 0;
        newRowConfig[k].isFailed = false;

        res.push(Promise.resolve()
          .then(() => {
            this.setState({rowConfigs: {...this.state.rowConfigs, [k]: newRowConfig[k]}});
          })
          .then(() => {
            // Put file into S3 (key, file, progressCallback)
            return(Storage.put(
              [roomId, prefixFileName(this.state.rowConfigs[k].uploadName)].join("/"),
              this.state.rowConfigs[k].uploadFile,
              {
                progressCallback: (progress) => {
                  this.updateProgress(k, 100*(progress.loaded/progress.total));
                }
              }
            ));
          })
          .then(result => {
            this.markComplete(k);
          })
          .catch(err => {
            this.markFailed(k);
            this.handleFailError(err);
            console.log(err);
          }));
      }
    }
    Promise.all(res).then(() =>
      this.setState({isUploading: false}));
  }
  setFile = (rowId, file, fileName) => {
    const maxRowId = Math.max(...Object.keys(this.state.rowConfigs).map((cv) => Number(cv)));
    if (rowId === maxRowId) {
      // Add a new row
      this.setState({
        // uploadNames: {...this.state.uploadNames, [rowId]: fileName, [this.state.lastRow+1]: ""},
        // uploadFiles: {...this.state.uploadFiles, [rowId]: file, [this.state.lastRow+1]: null},
        rowConfigs: {...this.state.rowConfigs,
          [rowId]: {
            uploadName: fileName,
            uploadFile: file,
            isUploading: false,
            isComplete: false,
            uploadProgress: 0
          },
          [this.state.lastRow+1]: {
            uploadName: "",
            uploadFile: null,
            isUploading: false,
            isComplete: false,
            uploadProgress: 0
          }
        },
        lastRow: this.state.lastRow + 1,
      })
    } else {
      this.setState({
        // uploadNames: {...this.state.uploadNames, [rowId]: fileName},
        // uploadFiles: {...this.state.uploadFiles, [rowId]: file},
        rowConfigs: {...this.state.rowConfigs,
          [rowId]: {
            uploadName: fileName,
            uploadFile: file,
            isUploading: false,
            isComplete: false,
            uploadProgress: 0
          }
        }
      });
    }
  };
  deleteFile = (rowId) => {
    // var newUploadNames = {},
    //     newUploadFiles = {},
    var newRowConfigs = {};
    for (let r of Object.keys(this.state.rowConfigs)) {
      if (Number(r) !== rowId) {
        // newUploadFiles[r] = this.state.uploadFiles[r];
        // newUploadNames[r] = this.state.uploadNames[r];
        newRowConfigs[r] = this.state.rowConfigs[r];
      }
    }
    this.setState({
      // uploadNames: {...newUploadNames},
      // uploadFiles: {...newUploadFiles},
      rowConfigs: {...newRowConfigs},
    });
  };
  _getRoomId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return(urlParams.get('room'));
  }
  _validateRoomId = (roomId) => {
    return(roomId && roomId.length >= config.minRoomIdLength);
  }

  render() {
    const roomId = this._getRoomId();

    const fileRows = Object.keys(this.state.rowConfigs).map((cv, i) =>
      <Grid item key={cv}>
        <UploadFileRow
          key={cv}
          rowId={Number(cv)}
          fileName={this.state.rowConfigs[cv].uploadName}
          file={this.state.rowConfigs[cv].uploadFile}
          textFieldLabel={(this.state.rowConfigs[cv].uploadFile)
            ? "File size: " + formatBytes(this.state.rowConfigs[cv].uploadFile.size)
            : "File name"}
          addFile={this.setFile}
          removeFile={this.deleteFile}
          disableRemove={!this.state.rowConfigs[cv].uploadName}
          isLoading={this.state.rowConfigs[cv].isUploading}
          isComplete={this.state.rowConfigs[cv].isComplete}
          isFailed={this.state.rowConfigs[cv].isFailed}
          progress={this.state.rowConfigs[cv].uploadProgress}
        />
      </Grid>
    );
    const errorMsg = (this.state.errorMessage)
      ? <Typography color="error" variant="body2" sx={{mt: 2}}>
          {this.state.errorMessage}
        </Typography>
      : "";

    return (
      <BackgroundBox>
        <Container maxWidth="md">
          <Paper sx={{display: {xs: 'none', md: 'flex'},
                      marginTop: 12,
                      marginBottom: 12,
                      padding: 2,
                      flexDirection: 'column',
                      alignItems: 'flexStart' }}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={5}>
                <LogoCardMedia image={logoImage} />
                <Typography variant="caption">
                  {(roomId) ? "Upload Room: " + roomId : ""}
                </Typography>
                {errorMsg}
              </Grid>
              <Grid item md={1}/>
              <Grid item container md={6} direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="subtitle1">
                    Please select files to securely upload:
                  </Typography>
                </Grid>
                {fileRows}
                <Grid item sx={{textAlign: 'center'}}>
                  <TermsNotice/>
                </Grid>
                <Grid item sx={{textAlign: 'center'}}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={this.state.isUploading}
                    onClick={this.uploadFile}
                  >
                    Upload Files
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{display: {xs:'flex', md:'none'},
                      marginTop: 6,
                      marginBottom: 6,
                      padding: 2,
                      flexDirection: 'column',
                      alignItems: 'flexStart'}}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <LogoCardMedia image={logoImage} />
                <Typography variant="caption">
                  {(roomId) ? "Upload Room: " + roomId : ""}
                </Typography>
                {errorMsg}
              </Grid>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="subtitle1">
                    Please select files to securely upload:
                  </Typography>
                </Grid>
                {fileRows}
                <Grid item sx={{textAlign: 'center'}}>
                  <TermsNotice/>
                </Grid>
                <Grid item sx={{textAlign: 'center'}}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={this.state.isUploading}
                    onClick={this.uploadFile}
                  >
                    Upload Files
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Footer>
          <Link
            href={termsUrl}
            color="inherit"
            variant="caption"
            underline="none"
            sx={{pr: 1, pl: 1}}
          >
            Terms of use
          </Link>
          <Link
            href={privacyUrl}
            color="inherit"
            variant="caption"
            underline="none"
            sx={{pr: 1, pl: 1}}
          >
            Privacy policy
          </Link>
        </Footer>
      </BackgroundBox>
    );
  }
}

export default UploadPanel;
